import { createModal } from '../modal';
import { addClass, removeClass } from '../utils';

// edit
document.addEventListener('turbolinks:load', () => {
  const trigger = document.querySelector('.js-file-encoding-modal-trigger');
  if (trigger === null) {
    return;
  }

  trigger.addEventListener('click', (event) => {
    event.preventDefault();
    const modal = createModal(document.querySelector('.m-modal'), () => {});
    modal.show();
  });  
}, false);

document.addEventListener('turbolinks:load', () => {
  const fileField: HTMLInputElement = document.querySelector('.js-attendance-csv');

  function drawPreview() {
    // セレクトボックスの項目を取得
    const employee_number: HTMLSelectElement = document.querySelector('#employee_number');
    const date: HTMLSelectElement = document.querySelector('#date');
    const start_time: HTMLSelectElement = document.querySelector('#start_time');
    const end_time: HTMLSelectElement = document.querySelector('#end_time');
    const break_time: HTMLSelectElement = document.querySelector('#break_time');
    const selectedItems = {
      employee_number: employee_number.value,
      date: date.value,
      start_time: start_time.value,
      end_time: end_time.value,
      break_time: break_time.value,
    }

    // hiddenからcsvのデータを取得
    const csvItemsElement: HTMLInputElement = document.querySelector('#csv_items');
    const csvItems: Array<Object> = JSON.parse(csvItemsElement.value);

    // csvのデータを表示
    let insertElement = '';
    csvItems.forEach((csvItem) => {
      insertElement += '<div class="m-PreviewList__tr">';
      Object.keys(selectedItems).forEach((key: string) => {
        const selectedItem = selectedItems[key];
        if (selectedItem) {
          const value = csvItem[selectedItem]
          insertElement += `<div class="m-PreviewList__td"><div>${value ? value : '​'}</div></div>`;
        } else {
          insertElement += `<div class="m-PreviewList__td"><div>ー</div></div>`;
        }
      });
      insertElement += '</div>';
    });
    document.querySelector('.m-PreviewList__tbody').innerHTML = insertElement;
    removeClass(document.querySelector('#preview'), 'm-hide');
  }

  if (fileField !== null) {
    const showAttendanceButton: HTMLInputElement = document.querySelector('#show-attendance-button');

    fileField.addEventListener('change', () => {
      if (fileField.files.length > 0) {
        // ファイルチェックを行うためのパラメータをhiddenにセットしてsubmit
        const insertForm = document.querySelector('#js_attendance_bulk_insert_update_form') as HTMLFormElement;
        const checkInputEl = document.createElement('input');
        checkInputEl.setAttribute('type', 'hidden');
        checkInputEl.setAttribute('name', 'file_check_only');
        insertForm.appendChild(checkInputEl);

        const removedChild = document.querySelector('.m-frmFile__name');
        if (removedChild !== null) {
          document.querySelector('.m-frmFile__upload').removeChild(removedChild);
        }
        const child = document.createElement('span');
        child.className = 'm-frmFile__name';
        child.textContent = fileField.files[0].name;
        document.querySelector('.m-frmFile__upload').appendChild(child);
        showAttendanceButton.disabled = false;
        addClass(document.querySelector('.m-frmFile label'), 'is-changed');
      }
    }, false);

    showAttendanceButton.addEventListener('click', (event) => {
      event.preventDefault();

      // ファイルチェック用のinput要素を削除
      const fileCheckInputEl = document.getElementsByName('file_check_only')[0];
      if (fileCheckInputEl) fileCheckInputEl.remove();

      const reader = new FileReader();
      reader.onload = () => {
        // 勤怠情報連携の表を表示、エラーの要素を非表示にする
        removeClass(document.querySelector('#attendanceItems'), 'm-hide');
        addClass(document.querySelector('#attendance-csv-read-error'), 'm-hide');

        // CSVのデータが0件の場合はセレクトボックスを削除
        const lines = (reader.result as string).trim().split('\n');
        if (lines.length === 0) {
          document.querySelector('.attendanceSelectBox').remove()
          return;
        }

        // CSVのデータを変数にセット
        const headers = <Array<string>>lines[0].split(',');
        let items = []
        for (let index = 1; index < lines.length && index <= 5; index++) {
          const item = {};
          lines[index].split(',').forEach((value: string, i: number) => {
            item[headers[i]] = value;
          })
          items.push(item);
        }
        const csv_items: HTMLInputElement = document.querySelector('#csv_items')
        csv_items.value = JSON.stringify(items);

        // セレクトボックスに未選択の項目を表示するため、先頭に空白をセット。
        headers.unshift('');
        
        // ヘッダの項目をセレクトボックスにセット
        let insertElement = '';
        headers.forEach((header: string) => {
          insertElement += `<option value="${header}">${header}</option>`
        });
        document.querySelectorAll('.attendanceSelectBox').forEach((element: HTMLSelectElement) => {
          element.innerHTML = insertElement;
        });

        // 前回連携時のマッピングを復元
        const column_mappings_json: HTMLInputElement = document.querySelector('#attendance_record_column_mappings');
        if (column_mappings_json.value !== '') {
          const column_mappings = JSON.parse(column_mappings_json.value);
          for (const [key, value] of Object.entries(column_mappings)) {
            const options = document.querySelectorAll(`#${key} option`);
            const target_option = [].find.call(options, function(ele){ return ele.text === value; });
            if (target_option !== undefined) {
              target_option.selected = true;
            }
          }

          // プレビューを表示
          drawPreview();
        }
      };
      reader.onerror = () => {
        addClass(document.querySelector('#attendanceItems'), 'm-hide');
        removeClass(document.querySelector('#attendance-csv-read-error'), 'm-hide');
      };
      reader.readAsText(fileField.files[0], (document.querySelector('#character_encoding') as HTMLInputElement).value);
    }, false);

    // 「紐づける項目」のセレクトボックス変更時にCSVのデータを再表示
    const linkItemSelectBoxes = document.querySelectorAll('.attendanceSelectBox');
    linkItemSelectBoxes.forEach((selectBox: HTMLSelectElement) => {
      selectBox.addEventListener('change', () => {
        drawPreview();
      });
    });
  }
}, false);

document.addEventListener('turbolinks:load', () => {
  const btn = document.querySelector('#attendance-records-delete-button .m-btnS');
  if (btn === null) {
    return;
  }

  btn.addEventListener('click', (event) => {
    const flg = <HTMLInputElement> document.querySelector('#is_attendance_records_exists');

    if (flg.value === 'false') {
      event.preventDefault();
      btn.removeAttribute('data-confirm');
      alert(btn.getAttribute('data-alert'));
    }
  });
}, false);
