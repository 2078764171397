import Chart from 'chart.js';

document.addEventListener('turbolinks:load', () => {
  const elements: Array<HTMLTableHeaderCellElement> = Array.from(document.querySelectorAll('#process_logs_table tr'));
  if (elements.length > 0) {
    let height = 0;
    elements.slice(0, 10).forEach((elem: HTMLTableHeaderCellElement) => { height += elem.offsetHeight });
    document.getElementById("process_logs_table").style.height = `${height}px`;
  }
});

function drawChart(displayInterval: number) {
  const canvas = <HTMLCanvasElement> document.getElementById('chart');
  const ctx = canvas.getContext('2d');
  const displayIntervalSelectBox = <HTMLInputElement> document.getElementById(`${displayInterval}min`);
  const data = JSON.parse(displayIntervalSelectBox.value);
  const chart = new Chart(ctx, {
      type: 'bar',

      data: {
          labels: data.time,
          datasets: [
            { label: 'キーボード打鍵回数', data: data.key_down_count, backgroundColor: 'rgba(93, 202, 87, 1.0)' },
            { label: 'クリック回数', data: data.mouse_click_count, backgroundColor: 'rgba(43, 182, 36, 1.0)' },
            { label: 'スクロール回数', data: data.mouse_wheel_count, backgroundColor: 'rgba(162, 227, 159, 1.0)' },
          ]
      },

      options: {
        legend: {
          position: 'bottom'
        },
        scales: {
          xAxes: [{
            stacked: true,
            type: 'time',
            time: {
                parser: 'HH:mm',
                unit: 'hour',
                stepSize: 0.5,
                displayFormats: {
                  'hour': 'H時'
                }
            },
            ticks: {
                min: '00:00',
                max: '24:00',
                callback: (value, index, values) => {
                  // 0時を除く偶数の時間のみラベルを表示する
                  if (index % 4 === 0 && index >= 2) {
                    return value === '0時' ? '24時' : value;
                  }
                  return '';
                }
            },
            maxBarThickness: 25
          }],
          yAxes: [{
            stacked: true,
            display: false
          }]
        },
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            title: (tooltipItem, chart) => {
              const time = tooltipItem[0].label.split(':');
              const hour = parseInt(time[0]);
              const min = time[1] - (time[1] % displayInterval);

              const start_time = `${hour}:${('00' + min).slice(-2)}`
              let end_time = ''
              if (min + displayInterval === 60) {
                end_time = `${hour + 1}:00`;
              } else {
                end_time = `${hour}:${('00' + (min + displayInterval)).slice(-2)}`;
              }

              return `${start_time} - ${end_time}`;
            }
          }
        }
      }
  });

  return chart;
}

document.addEventListener('turbolinks:load', () => {
  const displayInterval = <HTMLInputElement> document.getElementById('display_interval');
  if (displayInterval) {
    let chart = drawChart(parseInt(displayInterval.value))

    displayInterval.addEventListener('change', function() {
      if (chart) {
        chart.destroy();
      }
      chart = drawChart(parseInt(displayInterval.value))
    });
  }
});