document.addEventListener('turbolinks:load', () => {
  const monthsSelect = document.getElementById('contract_months');
  if (monthsSelect === null) {
    return;
  }
  document.querySelectorAll('#js-form-contract-edit input[type=radio]').forEach((radio) => {
    radio.addEventListener('change', (event) => {
      const element = event.target as HTMLInputElement;
      monthsSelect.innerHTML = '';
      window.plansByUserLicenses[element.value].forEach((plan: { months: string; text: string }) => {
        const option = document.createElement('option');
        monthsSelect.appendChild(option);
        option.innerText = plan.text;
        option.value = plan.months;
      });
    });
  });
});