import Cropper from 'cropperjs';
import { SUPPORTED_MIME_TYPES, isSupportedFileType } from '../image';
import { addClass, removeClass } from '../utils';
import { cropImage } from '../crop_image';

const IS_CHANGED = 'is-changed';
const M_HIDE = 'm-hide';

document.addEventListener('turbolinks:load', () => {
  // /console/account_settings
  const iconRadios = document.querySelectorAll('.js-selectIcon');
  if (iconRadios.length !== 2) {
    return;
  }

  iconRadios.forEach((element: HTMLInputElement) => {
    element.addEventListener('change', (event) => {
      // TODO slide down/up トランジションを実装する
      const radio: HTMLInputElement = event.target as HTMLInputElement;
      const element: HTMLElement = document.querySelector('.m-listRadio__content');
      const iconUpdateConfirmMessage: string = document.querySelector('.m-frmRadio.is-default').getAttribute('data-confirm-message-js');
      const isDefaultIcon: string = element.getAttribute('data-is-default-icon-js');
      const fileUploadElement: HTMLElement = document.querySelector('.m-frmFile');
      const fileUploadInputElement: HTMLInputElement = document.querySelector('.m-frmFile__upload > input');
      const originalIconRadioElement: HTMLInputElement = document.querySelector('#user_radio_icon_original');
      if (radio.value === 'default') {
        element.style.display = 'none';
        if (isDefaultIcon !== 'true') {
          if (confirm(iconUpdateConfirmMessage)) {
            (document.querySelector('#form_user_icon') as HTMLFormElement).submit();
          } else {
            event.preventDefault();
            originalIconRadioElement.checked = true;
            element.style.display = 'block';
            return false;
          }
        }
      } else {
        fileUploadElement.style.display = 'block';
        fileUploadInputElement.removeAttribute("disabled");
        element.style.display = 'block';
        return false;
      }
      (document.querySelector('#user_icon') as HTMLInputElement).disabled = (radio.value === 'default');
    });
    // オリジナルアイコン初期表示時処理
    if (element.value === 'original') {
      const orgIconContent: HTMLElement = document.querySelector('.m-listRadio__content');
      orgIconContent.style.display = 'block';
    }
  });

  cropImage('#form_user_icon', '#user_cropped_image', 60, 60);
}, false);
