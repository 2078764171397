import { addClass, removeClass } from './utils';
import { createModal } from './modal';

declare global {
  interface Window { plansByUserLicenses: Map<string, Array<any>>; }
}

const IS_ACTIVE = 'is-active';
const ARIA_CONTROLS = 'aria-controls';
const ARIA_EXPANDED = 'aria-expanded';
const ARIA_HIDDEN = 'aria-hidden';
const TRUE = 'true';
const FALSE = 'false';

document.addEventListener('turbolinks:load', () => {
  // マイメニュー
  const triggers: NodeListOf<Element> = document.querySelectorAll('.js-hmenu');
  if (triggers === null) {
    return;
  }

  triggers.forEach((trigger:HTMLElement) => {
    trigger.addEventListener('click', (event) => {
      event.preventDefault();
      const menu = document.getElementById(trigger.getAttribute(ARIA_CONTROLS));
      const expanded = trigger.getAttribute(ARIA_EXPANDED);
      if (expanded === FALSE) {
        addClass(trigger, IS_ACTIVE);
        trigger.setAttribute(ARIA_EXPANDED, TRUE);
        addClass(menu, IS_ACTIVE);
        menu.setAttribute(ARIA_HIDDEN, FALSE);
      } else if (expanded === TRUE) {
        removeClass(trigger, IS_ACTIVE);
        trigger.setAttribute(ARIA_EXPANDED, FALSE);
        removeClass(menu, IS_ACTIVE);
        menu.setAttribute(ARIA_HIDDEN, TRUE);
      }
    });
  });
}, false);

// ヘルプアイコン -> ポップアップ
document.addEventListener('turbolinks:load', () => {
  const triggers: NodeListOf<Element> = document.querySelectorAll('.js-help-trigger');
  if (triggers === null) {
    return;
  }
  const clickListener = (event: MouseEvent) => {
    event.preventDefault();
  };
  const mouseListener = (event: MouseEvent) => {
    const trigger: HTMLElement = event.target as HTMLElement;
    const popup = document.getElementById(trigger.getAttribute(ARIA_CONTROLS));
    if (popup === null) {
      return;
    }
    switch (trigger.getAttribute(ARIA_EXPANDED)) {
      case TRUE:
        removeClass(trigger, IS_ACTIVE);
        trigger.setAttribute(ARIA_EXPANDED, FALSE);
        removeClass(popup, IS_ACTIVE);
        popup.setAttribute(ARIA_HIDDEN, TRUE);
        break;
      case FALSE:
        addClass(trigger, IS_ACTIVE);
        trigger.setAttribute(ARIA_EXPANDED, TRUE);
        addClass(popup, IS_ACTIVE);
        popup.setAttribute(ARIA_HIDDEN, FALSE);
        break;
    }
  };

  triggers.forEach((trigger:HTMLElement) => {
    trigger.addEventListener('click', clickListener, false);
    trigger.addEventListener('mouseover', mouseListener, false);
    trigger.addEventListener('mouseout', mouseListener, false);
  });
}, false);

document.addEventListener('turbolinks:load', () => {
  // readonlyの場合EnterでSubmitさせない
  const elements: NodeList = document.querySelectorAll('input[readonly=readonly]');

  elements.forEach((element) => {
    element.addEventListener('keypress', (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        return false;
      }
      return true;
    });
  });
}, false);

document.addEventListener('turbolinks:load', () => {
  // スペース選択
  const trigger: HTMLElement = document.querySelector('.js-select-space');
  if (trigger === null) {
    return;
  }

  trigger.addEventListener('click', (event) => {
    event.preventDefault();
    createModal(document.querySelector('.js-space-selector-modal')).show();
  });
}, false);