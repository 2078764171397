import { addClass, removeClass } from '../utils';

const COLUMNS = ['name', 'mail', 'employee_number', 'work_style', 'auth', 'group'];

document.addEventListener('turbolinks:load', () => {
  const fileField: HTMLInputElement = document.querySelector('.js-space-member-csv');
  if (fileField !== null) {
    const showMembersButton: HTMLInputElement = document.querySelector('#show-members-button');

    fileField.addEventListener('change', () => {
      if (fileField.files.length > 0) {
        // ファイル選択時に、許可していないヘッダがないかのチェック
        const memberInsertForm = document.querySelector('#js_member_bulk_insert_update_form, #js_member_details_bulk_update_update_form') as HTMLFormElement;
        const checkInputEl = document.createElement('input');
        checkInputEl.setAttribute('type', 'hidden');
        checkInputEl.setAttribute('name', 'file_check_only');
        memberInsertForm.appendChild(checkInputEl);

        const removedChild = document.querySelector('.m-frmFile__name');
        if (removedChild !== null) {
          document.querySelector('.m-frmFile__upload').removeChild(removedChild);
        }
        const child = document.createElement('span');
        child.className = 'm-frmFile__name';
        child.textContent = fileField.files[0].name;
        document.querySelector('.m-frmFile__upload').appendChild(child);
        showMembersButton.disabled = false;
        addClass(document.querySelector('.m-frmFile label'), 'is-changed');
      }
    }, false);

    showMembersButton.addEventListener('click', (event) => {
      event.preventDefault();

      // ファイルヘッダチェック用のinput要素を削除
      const fileCheckInputEl = document.getElementsByName('file_check_only')[0];
      if (fileCheckInputEl) fileCheckInputEl.remove();

      const reader = new FileReader();
      reader.onload = () => {
        removeClass(document.querySelector('#members'), 'm-hide');
        addClass(document.querySelector('#member-csv-read-error'), 'm-hide');

        const lines = (reader.result as string).trim().split('\n');
        if (lines.length === 0) {
          document.querySelector('.m-listMemberlist__tbody').innerHTML = '';
          return;
        }
        const dataArray = [];
        for (let i = 0, l = lines.length; i < l; i++) {
          const rows = lines[i].trim().split(',');
          if (rows.length > 0) {
            dataArray[i] = lines[i].split(',');
          }
        }
        let insertElement = '';
        dataArray.shift();
        dataArray.forEach((row) => {
          insertElement += '<div class="m-listMemberlist__tr">';
          row.forEach((column: string[], index: number) => {
            if (COLUMNS.includes(COLUMNS[index])) { // サポートしていないヘッダは表示しない
              insertElement += `<div class="m-listMemberlist__td" data-cell="${COLUMNS[index]}"><div>${column}</div></div>`;
            }
          });
          insertElement += '</div>';
        });
        document.querySelector('.m-listMemberlist__tbody').innerHTML = insertElement;
      };
      reader.onerror = () => {
        addClass(document.querySelector('#members'), 'm-hide');
        removeClass(document.querySelector('#member-csv-read-error'), 'm-hide');
      };
      reader.readAsText(fileField.files[0], (document.querySelector('#js_character_encoding') as HTMLInputElement).value);
    }, false);
  }

  document.querySelectorAll('.js-text-space-member-employee-number').forEach((text: HTMLInputElement) => {
    text.addEventListener('change', (event) => {
      const idField: HTMLInputElement = document.getElementById('js-user-id-to-change-employee-number') as HTMLInputElement;
      idField.value = text.getAttribute('data-user-id');
      const valueField: HTMLInputElement = document.getElementById('js-employee-number-to-change-member-employee-number') as HTMLInputElement;
      valueField.value = text.value;
      text.form.submit();
    });
  });

  document.querySelectorAll('select[form="js-form-space-member-work-style"]').forEach((select: HTMLSelectElement) => {
    select.addEventListener('change', (event) => {
      const idField: HTMLInputElement = document.getElementById('js-user-id-to-change-member-work-style') as HTMLInputElement;
      idField.value = select.getAttribute('data-user-id');
      const valueField: HTMLInputElement = document.getElementById('js-work-style-to-change-member-work-style') as HTMLInputElement;
      valueField.value = select.value;
      select.form.submit();
    });
  });

  document.querySelectorAll('select[form="js-form-space-member-role"]').forEach((select: HTMLSelectElement) => {
    select.addEventListener('change', (event) => {
      const confirmMessage = select.getAttribute('data-confirm');
      if (confirmMessage !== null && confirmMessage !== '' && !confirm(confirmMessage)) {
        event.preventDefault();
        return;
      }
      const idField: HTMLInputElement = document.getElementById('js-user-id-to-change-space-role') as HTMLInputElement;
      idField.value = select.getAttribute('data-user-id');
      const valueField: HTMLInputElement = document.getElementById('js-space-role-to-change-space-role') as HTMLInputElement;
      valueField.value = select.value;
      select.form.submit();
    });
  });

  document.querySelectorAll('.js-label-space-member-is-space-role-admin').forEach((label: HTMLLabelElement) => {
    label.addEventListener('click', (event) => {
      const checkbox: HTMLInputElement = document.getElementById(label.htmlFor) as HTMLInputElement;
      if (checkbox === null || checkbox.disabled) {
        return;
      }
      const confirmMessage = checkbox.getAttribute('data-confirm');
      if (confirmMessage !== null && confirmMessage !== '' && !confirm(confirmMessage)) {
        event.preventDefault();
        return;
      }
      const idField: HTMLInputElement = document.getElementById('js-user-id-to-change-space-role') as HTMLInputElement;
      idField.value = checkbox.getAttribute('data-user-id');
      const valueField: HTMLInputElement = document.getElementById('js-space-role-to-change-space-role') as HTMLInputElement;
      // 権限をtoggleするためクリック時のvalueと逆を設定。プルダウンをチェックボックスに変更したためboolではなく値をセットする
      valueField.value = checkbox.checked ? "user" : "admin";
      checkbox.form.submit();
    }, false);
  });

  document.querySelectorAll('.js-label-space-member-is-payment-manager').forEach((label: HTMLLabelElement) => {
    label.addEventListener('click', (event) => {
      const checkbox: HTMLInputElement = document.getElementById(label.htmlFor) as HTMLInputElement;
      if (checkbox === null || checkbox.disabled) {
        return;
      }
      const confirmMessage = checkbox.getAttribute('data-confirm');
      if (confirmMessage !== null && confirmMessage !== '' && !confirm(confirmMessage.replace('\\n', '\n'))) {
        event.preventDefault();
        return;
      }
      const field: HTMLInputElement = document.getElementById('js-user-id-to-toggle-is-payment-manager') as HTMLInputElement;
      field.value = checkbox.getAttribute('data-user-id');
      checkbox.form.submit();
    }, false);
  });

  document.querySelectorAll('.js-label-space-member-is-security-manager').forEach((label: HTMLLabelElement) => {
    label.addEventListener('click', (event) => {
      const checkbox: HTMLInputElement = document.getElementById(label.htmlFor) as HTMLInputElement;
      if (checkbox === null || checkbox.disabled) {
        return;
      }
      const confirmMessage = checkbox.getAttribute('data-confirm');
      if (confirmMessage !== null && confirmMessage !== '' && !confirm(confirmMessage.replace('\\n', '\n'))) {
        event.preventDefault();
        return;
      }
      const field: HTMLInputElement = document.getElementById('js-user-id-to-toggle-is-security-manager') as HTMLInputElement;
      field.value = checkbox.getAttribute('data-user-id');
      checkbox.form.submit();
    }, false);
  });

  document.querySelectorAll('button[form="js-form-space-member-reinvite"]').forEach((button: HTMLButtonElement) => {
    button.addEventListener('click', () => {
      const field: HTMLInputElement = document.getElementById('js-user-id-to-reinvite') as HTMLInputElement;
      field.value = button.getAttribute('data-user-id');
      button.form.submit();
    }, false);
  });

  document.querySelectorAll('.js-label-space-member-available').forEach((label: HTMLLabelElement) => {
    label.addEventListener('click', (event) => {
      const checkbox: HTMLInputElement = document.getElementById(label.htmlFor) as HTMLInputElement;
      if (checkbox === null || checkbox.disabled) {
        return;
      }
      const confirmMessage = checkbox.getAttribute('data-confirm');
      if (confirmMessage !== null && confirmMessage !== '' && !confirm(confirmMessage)) {
        event.preventDefault();
        return;
      }
      const field: HTMLInputElement = document.getElementById('js-user-id-to-toggle-available') as HTMLInputElement;
      field.value = checkbox.getAttribute('data-user-id');
      checkbox.form.submit();
    }, false);
  });

  document.querySelectorAll('button[form="js-form-space-member-destroy"]').forEach((button: HTMLButtonElement) => {
    button.addEventListener('click', () => {
      const confirmMessages = [button.getAttribute('data-confirm')];
      const myselfMessage = button.getAttribute('data-confirm-myself');
      if (myselfMessage !== null && myselfMessage !== '') {
        confirmMessages.push(myselfMessage);
      }
      if (confirmMessages.every((message) => confirm(message))) {
        const field: HTMLInputElement = document.getElementById('js-user-id-to-destroy') as HTMLInputElement;
        field.value = button.getAttribute('data-user-id');
        button.form.submit();
      }
    })
  });
}, false);

// 退会理由のチェックボックスの必須チェック
document.addEventListener('turbolinks:load', () => {
  const formReasonWithdrawal = document.getElementById('js-form-reason-withdrawal')
  if (formReasonWithdrawal === null) {
    return;
  }
  const button = formReasonWithdrawal.querySelector('input.m-btn') as HTMLInputElement;
  const elements = document.getElementsByName('withdrawal_reason[reasons][]') as NodeListOf<HTMLInputElement>;

  button.addEventListener('click', () => {
    if (document.querySelectorAll('input[name="withdrawal_reason[reasons][]"]:checked').length === 0) {
      elements[0].setCustomValidity(elements[0].dataset.msg);
    }
  });

  elements.forEach(element => {
    element.addEventListener('click', () => {
      elements[0].setCustomValidity('');
    });
  });
}, false);

// 退会理由のテキストエリアの必須チェック
document.addEventListener('turbolinks:load', () => {
  const formReasonWithdrawal = document.getElementById('js-form-reason-withdrawal')
  if (formReasonWithdrawal === null) {
    return;
  }
  const button = formReasonWithdrawal.querySelector('input.m-btn') as HTMLInputElement;
  const textarea = document.getElementById('withdrawal_reason_others') as HTMLTextAreaElement;

  button.addEventListener('click', () => {
    if (textarea.value.match(/^[\s|　]+$/)) {
      textarea.setCustomValidity(textarea.dataset.msg);
    }
  });

  textarea.addEventListener('keyup', () => {
    textarea.setCustomValidity('');
  });
}, false);
