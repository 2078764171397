import { addClass, removeClass } from '../utils';
import { createModal } from '../modal';
import Sortable from 'sortablejs';
import Rails from '@rails/ujs';
import { cropImage } from '../crop_image';

const COLUMNS = ['name', 'mail', 'employee_number', 'work_style', 'auth'];

// edit
document.addEventListener('turbolinks:load', () => {
  const trigger = document.querySelector('.js-member-list-modal-trigger');
  if (trigger === null) {
    return;
  }

  trigger.addEventListener('click', (event) => {
    event.preventDefault();
    const modal = createModal(document.querySelector('.m-modal'), () => {
      (document.getElementById('js-addable-users-search-button') as HTMLInputElement).click();
    }, () => {
      (document.getElementById('keyword') as HTMLInputElement).value = '';
      document.getElementById('addable-users').textContent = '';
    });
    modal.show();
  });
}, false);

// member_bulk_insert
document.addEventListener('turbolinks:load', () => {
  const fileField: HTMLInputElement = document.querySelector('.js-group-member-csv');
  if (fileField === null) {
    return;
  }
  const showMembersButton: HTMLInputElement = document.querySelector('#show-members-button');

  fileField.addEventListener('change', () => {
    if (fileField.files.length > 0) {

      // ファイル選択時に、許可していないヘッダがないかのチェック
      const memberInsertForm = document.querySelector('#js_member_bulk_insert_update_form') as HTMLFormElement;
      const checkInputEl = document.createElement('input');
      checkInputEl.setAttribute('type', 'hidden');
      checkInputEl.setAttribute('name', 'file_check_only');
      memberInsertForm.appendChild(checkInputEl);

      const removedChild = document.querySelector('.m-frmFile__name');
      if (removedChild !== null) {
        document.querySelector('.m-frmFile__upload').removeChild(removedChild);
      }
      const child = document.createElement('span');
      child.className = 'm-frmFile__name';
      child.textContent = fileField.files[0].name;
      document.querySelector('.m-frmFile__upload').appendChild(child);
      showMembersButton.disabled = false;
      addClass(document.querySelector('.m-frmFile label'), 'is-changed');
    }
  }, false);

  showMembersButton.addEventListener('click', (event) => {
    event.preventDefault();

    // ファイルヘッダチェック用のinput要素を削除
    const fileCheckInputEl = document.getElementsByName('file_check_only')[0];
    if (fileCheckInputEl) fileCheckInputEl.remove();

    const reader = new FileReader();
    reader.onload = () => {
      removeClass(document.querySelector('#members'), 'm-hide');
      addClass(document.querySelector('#member-csv-read-error'), 'm-hide');

      const lines = (reader.result as string).trim().split('\n');
      if (lines.length === 0) {
        document.querySelector('.m-listMemberlist__tbody').innerHTML = '';
        return;
      }
      const dataArray = [];
      for (let i = 0, l = lines.length; i < l; i++) {
        const rows = lines[i].trim().split(',');
        if (rows.length > 0) {
          dataArray[i] = lines[i].split(',');
        }
      }
      let insertElement = '';
      dataArray.shift();
      dataArray.forEach((row) => {
        insertElement += '<div class="m-listMemberlist__tr">';
        row.forEach((column: string[], index: number) => {
          if (COLUMNS.includes(COLUMNS[index])) { // サポートしていないヘッダは表示しない
            insertElement += `<div class="m-listMemberlist__td" data-cell="${COLUMNS[index]}"><div>${column}</div></div>`;
          }
        });
        insertElement += '</div>';
      });
      document.querySelector('.m-listMemberlist__tbody').innerHTML = insertElement;
    };
    reader.onerror = () => {
      addClass(document.querySelector('#members'), 'm-hide');
      removeClass(document.querySelector('#member-csv-read-error'), 'm-hide');
    };
    reader.readAsText(fileField.files[0], (document.querySelector('#js_character_encoding') as HTMLInputElement).value);
  }, false);

}, false);

document.addEventListener('turbolinks:load', () => {
  const el = document.querySelector('.m-listGroup__tbody');
  if (el != null) {
    const addGrabbing = () => addClass(document.querySelector('html'), 'is-grabbing');
    const removeGrabbing = () => removeClass(document.querySelector('html'), 'is-grabbing');
    Sortable.create(el, {
      handle: '.m-listGroup__td[data-cell="edit"]',
      direction: 'vertical',
      forceFallback : true,
      onChoose: addGrabbing,
      onStart: addGrabbing,
      onUnchoose: removeGrabbing,
      onEnd: removeGrabbing,
      onUpdate: (evt) => {
        (document.querySelector('#js-move-group-form input[name="from"]') as HTMLInputElement).value = evt.oldIndex;
        (document.querySelector('#js-move-group-form input[name="to"]') as HTMLInputElement).value = evt.newIndex;
        Rails.fire((document.getElementById('js-move-group-form') as HTMLFormElement), 'submit');
      }
    });
  }
}, false);

document.addEventListener('turbolinks:load', () => {
  cropImage('#form_background_image', '#group_cropped_image', 330, 150);
}, false);
