import { addClass, removeClass } from './utils';

const IS_ACTIVE = 'is-active';
const IS_FIXED = 'is-fixed';
const ESC = 27;

export const createModal = (modal: HTMLElement, onShown?: () => void, onHidden?: () => void) => {
  const show = () => {
    addClass(document.querySelector('html'), IS_FIXED);
    addClass(modal, IS_ACTIVE);
    modal.style.display = 'block';

    setTimeout(() => {
      modal.style.opacity = '1';
      (modal.querySelector('.m-modalContent') as HTMLElement).focus();
      const decendants = modal.querySelectorAll('*');
      document.querySelectorAll('body *').forEach((element) => {
        for (let i = 0; i < decendants.length; i++) {
          if (decendants[i] === element) {
            return;
          }
        }
        element.setAttribute('tabindex', '-1');
      });
      adjust();
      if (typeof onShown === 'function') {
        onShown();
      }
    }, 100);

    document.addEventListener('keydown', onEscKeyDown, false);
    modal.querySelector('.m-modalOverlay').addEventListener('click', hide, false);
    modal.querySelector('.js-modalClose').addEventListener('click', hide, false);
  };

  const adjust = () => {
    const headerHeight = document.querySelector('.l-headerContent').clientHeight;
    adjustInner();
    window.removeEventListener('resize', adjustInner);

    function adjustInner() {
      const content: HTMLElement = modal.querySelector('.m-modalContent');
      const windowHeight = window.innerHeight;
      const contentHeight = content.clientHeight;
      const margin = Math.max(headerHeight, (windowHeight - contentHeight) / 2);
      content.style.marginTop = `${margin}px`;
      content.style.marginBottom = `${margin}px`;
    }
  }

  const hide = () => {
    setTimeout(() => {
      modal.style.opacity = '0';
      removeClass(document.querySelector('html'), IS_FIXED);
      const decendants = modal.querySelectorAll('*');
      document.querySelectorAll('body *').forEach((element) => {
        for (let i = 0; i < decendants.length; i++) {
          if (decendants[i] === element) {
            return;
          }
        }
        element.removeAttribute('tabindex');
      });
      if (typeof onHidden === 'function') {
        onHidden();
      }
    }, 100);

    setTimeout(() => {
      modal.style.display = 'none';
      removeClass(modal, IS_ACTIVE);
    }, 400);

    document.removeEventListener('keydown', onEscKeyDown, false);
    modal.querySelector('.m-modalOverlay').removeEventListener('click', hide, false);
    modal.querySelector('.js-modalClose').removeEventListener('click', hide, false);
  };

  const onEscKeyDown = (event: any) => {
    if (event.keyCode == ESC) {
      hide();
    }
  };

  return {
    show,
    hide,
  }
};
