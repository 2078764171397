if (location.pathname === '/register/sign_up') {
  document.addEventListener('turbolinks:load', () => {
    const spaceName: HTMLInputElement = <HTMLInputElement>document.getElementById('_space_name');
    const extraInfosCorporateName: HTMLInputElement = <HTMLInputElement>document.getElementById('_extra_info_corporate_name');
  
    if (spaceName !== null && extraInfosCorporateName !== null) {
      spaceName.addEventListener('change', () => {
        if (extraInfosCorporateName.value.length == 0) {
          extraInfosCorporateName.value = spaceName.value
        }
      }, false);
    }

    const userName: HTMLInputElement = <HTMLInputElement>document.getElementById('_user_name');
    const extraInfosPersonName: HTMLInputElement = <HTMLInputElement>document.getElementById('_extra_info_person_name');
  
    if (userName !== null && extraInfosPersonName !== null) {
      userName.addEventListener('change', () => {
        if (extraInfosPersonName.value.length == 0) {
          extraInfosPersonName.value = userName.value
        }
      }, false);
    }
  });
}