// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery');
require("lib/jquery.ui.ympicker");
require("lib/jquery.ui.datepicker");
require('luminous-lightbox/dist/Luminous');
require('chart.js/dist/Chart.js');
require("console");
require("console/account_settings");
require("console/group_settings");
require("console/space_settings");
require("console/tasks");
require("console/work_record_approval_procedures");
require("console/work_records");
require("console/contracts");
require("console/daily_reports");
require("console/meeting_urls");
require("register/sign_up");

import '../src/application.scss'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
