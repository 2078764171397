export const hasClass = (element: HTMLElement, className: string): boolean => {
  return 0 <= element.className.indexOf(className);
};

export const addClass = (element: HTMLElement, className: string): void => {
  if (hasClass(element, className)) {
    return;
  }
  element.className += ` ${className}`;
};

export const removeClass = (element: HTMLElement, className: string): void => {
  if (!hasClass(element, className)) {
    return;
  }
  element.className = element.className.replace(new RegExp(`\\s*${className}`, 'g'), '');
};
