import { createModal } from '../modal';

// show
document.addEventListener('turbolinks:load', () => {
  const trigger = document.querySelector('.js-work-record-approval-procedure-remand-modal-trigger');
  if (trigger === null) {
    return;
  }

  trigger.addEventListener('click', (event) => {
    event.preventDefault();
    const modal = createModal(document.querySelector('.m-modal'));
    modal.show();
  });
}, false);