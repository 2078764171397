export const SUPPORTED_MIME_TYPES = {
  'GIF': 'image/gif',
  'JPG': 'image/jpg',
  'JPEG': 'image/jpeg',
  'PNG': 'image/png',
};

const getImageFileType = (buffer: ArrayBuffer): string | null => {
  const u8a: Uint8Array = new Uint8Array(buffer);
  let str: string = '';
  let hex: string = '';
  for (let i = 0; i < 10; i++) {
    hex += u8a[i].toString(16); // hex
    str += String.fromCharCode(u8a[i]); // string
  }
  if (str.indexOf('GIF') != -1) {
    return SUPPORTED_MIME_TYPES.GIF;
  }
  if (hex.indexOf('ffd8') != -1) {
    return SUPPORTED_MIME_TYPES.JPG;
  }
  if (str.indexOf('PNG') != -1) {
    return SUPPORTED_MIME_TYPES.PNG;
  }
  return null;
};

export const isSupportedFileType = (reader: FileReader): boolean => {
  return Object.values(SUPPORTED_MIME_TYPES).includes(getImageFileType(reader.result as ArrayBuffer));
};
