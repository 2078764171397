document.addEventListener('turbolinks:load', () => {
  const meetingUrlForms = document.querySelectorAll('.meeting-url-form');
  // 会議URLの更新
  const updateMeetingUrlHandler = (event) => {
    if (event.target.form) {
      event.target.form.submit();
    }
  }
  meetingUrlForms.forEach((meetingUrlForm) => {
    meetingUrlForm.querySelectorAll('input[type=text]').forEach((textInput) => {
      textInput.addEventListener('change', updateMeetingUrlHandler);
    });
  });
});