import { createModal } from '../modal';

const toDoubleDigits = function(num) {
  num += "";
  if (num.length === 1) {
    num = "0" + num;
  }
 return num;
};

// 一覧ページプルダウンでのprogress切り替え
document.addEventListener('turbolinks:load', () => {
  const progressSelectBoxForm: HTMLFormElement = document.querySelector('#js_progress_select_form') as HTMLFormElement;
  if (!progressSelectBoxForm) return false;
  const progressSelectBox: HTMLElement = document.querySelector('#js_progress_select_box');
  progressSelectBox.addEventListener('change', () => {
    progressSelectBoxForm.submit();
  }, false);
}, false);

// タスク新規作成、編集ページでprogress切り替え
document.addEventListener('turbolinks:load', () => {
  const progressToggleBtns = document.querySelectorAll('.m-listState__item');
  if (progressToggleBtns.length !== 3) return false;

  const progressHiddenInput = document.getElementById('js_toggle_progress_hidden') as HTMLInputElement;
  if (!progressHiddenInput) return false;

  progressToggleBtns.forEach((element: HTMLElement) => {
    element.addEventListener('click', (event) => {
      progressToggleBtns.forEach((el: HTMLElement) => {
        el.classList.remove('is-current');
      });
      element.classList.add('is-current');
      progressHiddenInput.value = element.getAttribute('data-state');

      // 現在日時を取得
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var date = now.getDate();
      var hour = now.getHours();
      var min = now.getMinutes();
      const actualStartedAtYMD = document.querySelector('#actual_started_at_text') as HTMLInputElement
      const actualStartedAtHHMM = document.querySelector('#task_actual_started_at_hhmm') as HTMLInputElement
      const completedAtYMD = document.querySelector('#completed_at_text') as HTMLInputElement
      const completedAtHHMM = document.querySelector('#task_completed_at_hhmm') as HTMLInputElement
      const requiredTimeInput = document.querySelector('#task_required_time') as HTMLInputElement

      // フォームが空の時に現在日時をセット、所要時間の必須入力制御
      if (element.getAttribute('data-state') === 'wip') {
        if (!actualStartedAtYMD.value) actualStartedAtYMD.value = year + '-' + toDoubleDigits(month) + '-' + toDoubleDigits(date);
        if (!actualStartedAtHHMM.value) actualStartedAtHHMM.value = toDoubleDigits(hour) + ':' + min;
        requiredTimeInput.required = false;
      }
      else if (element.getAttribute('data-state') === 'yet') {
        requiredTimeInput.required = false;
      }
      else if (element.getAttribute('data-state') === 'fin') {
        if (!completedAtYMD.value) completedAtYMD.value = year + '-' + toDoubleDigits(month) + '-' + toDoubleDigits(date);
        if (!completedAtHHMM.value) completedAtHHMM.value = toDoubleDigits(hour) + ':' + min;
        requiredTimeInput.required = true;
      }
    }, false);
  })
}, false);

document.addEventListener('turbolinks:load', () => {
  const trigger = document.getElementById('js_task_group_search_modal_trigger');
  if (trigger === null) {
    return;
  }

  trigger.addEventListener('click', (event) => {
    event.preventDefault();
    const searchTaskFromTaskGroupInModalInput = document.querySelector('#js_task_group_search_modal_input') as HTMLInputElement;

    const modal = createModal(document.querySelector('.m-modal'), () => {
      (document.getElementById('modal_fetch_task_groups_input') as HTMLInputElement).value = (document.querySelector('.js-task-search-box-group-name') as HTMLInputElement).value;
      (document.getElementById('modal_fetch_task_groups_btn')).click();
      (document.getElementById('js_task_group_search_modal_btn')).click();
    }, () => {
      document.querySelector('.m-listMemberSelect__list').textContent = '';
    });

    // モーダル内でのタスクグループ絞り込み
    document.querySelectorAll('.m-listMemberSelect__item').forEach((element: HTMLElement) => {
      if (element.textContent.match(searchTaskFromTaskGroupInModalInput.value)) {
        element.style.display = '';
      } else {
        element.style.display = 'none';
      }
    });
    modal.show();
  });
}, false);

// タスクの外部カレンダーとの同期解除
document.addEventListener('turbolinks:load', () => {
  const synced: HTMLInputElement = document.querySelector('#js_edit_synced_hidden');

  if (synced === null || synced.value !== 'true') {
    return;
  }

  const updateButton: HTMLInputElement = document.querySelector('#edit_task_form input[type="submit"]');
  updateButton.addEventListener('click', (event) => {
    event.preventDefault();
    const form: HTMLFormElement = document.querySelector('#edit_task_form')
    const elements: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      '#taskName, #taskDetail, #scheduled_started_at_text, #task_scheduled_started_at_hhmm, #scheduled_completed_at_text, #task_scheduled_completed_at_hhmm'
    );
    let changeFlg = false;

    // タスク名称・タスク内容・開始予定時間・終了予定時間が変更されているかチェック
    elements.forEach(element => {
      const hiddenElement: HTMLInputElement = document.querySelector(`#js_edit_${element.id}_hidden`);
      if (element.value !== hiddenElement.value) {
        changeFlg = true;
      }
    });

    // 対象項目が変更されていない場合は処理せずsubmitを行う
    if (!changeFlg) {
      return form.submit();
    }

    // 対象項目が変更されている場合は警告メッセージを表示する
    const confirm_message: HTMLInputElement = document.querySelector('#js_edit_confirm_message_hidden');
    if (confirm(confirm_message.value)) {
      synced.value = 'false';
      form.submit()
    }
  });
}, false);
