import { addClass, removeClass } from './utils';
import Cropper from 'cropperjs';
import { SUPPORTED_MIME_TYPES, isSupportedFileType } from './image';
import { createModal } from './modal';

const IS_CHANGED = 'is-changed';
const M_HIDE = 'm-hide';

export const cropImage = (formSelector: string, croppedImageSelector: string, width: number, height: number)  => {
  const backgroundImageForm = document.querySelector(formSelector);

  if (backgroundImageForm === null) {
    return;
  }
  const fileInput = document.querySelector('input[type=file]') as HTMLInputElement;
  let cropper = null;
  backgroundImageForm.addEventListener('submit', (e) => {
    fileInput.value = '';
    if (cropper !== null) {
      const croppedCanvas = cropper.getCroppedCanvas({
        width: width,
        height: height
      });
      croppedCanvas.toBlob((size) => console.log(size));
      (document.querySelector(croppedImageSelector) as HTMLInputElement).value = croppedCanvas.toDataURL();
      cropper = null;
    }
  });

  const resetFileField = () => {
    removeClass(document.querySelector('.m-frmFile label'), IS_CHANGED);
    const child = document.querySelector('.m-frmFile__name');
    if (child !== null) {
      document.querySelector('.m-frmFile__upload').removeChild(child);
    }
    addClass(document.querySelector('#image-cropper'), M_HIDE);
    (document.querySelector('.m-frmFile .m-btn') as HTMLInputElement).disabled = true;
  }

  const showModal = () => {
    createModal(document.querySelector('.js-file-type-modal')).show();
  };

  fileInput.addEventListener('change', (event) => {
    const files = (event.target as HTMLInputElement).files;
    if (files === null || files.length < 1) {
      resetFileField();
      return;
    }
    const file = files[0];

    new Promise((resolve, reject) => { // fileのmime typeをチェックする。
      if (Object.values(SUPPORTED_MIME_TYPES).includes(file.type)) {
        resolve(file);
      } else {
        reject();
      }
    }).then((file: File) => { // ファイルの先頭を読んでファイルタイプを確認する。
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (isSupportedFileType(event.target)) {
            resolve(file);
          } else {
            reject();
          }
        };
        reader.readAsArrayBuffer(file);
      });
    }).then((file: File) => { // データURLを取得する。
      return new Promise((resolve) => {
        const child = document.querySelector('.m-frmFile__name') || document.createElement('span');
        child.className = 'm-frmFile__name';
        child.textContent = file.name;
        document.querySelector('.m-frmFile__upload').appendChild(child);
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        }
        reader.readAsDataURL(file);
      });
    }).then((url: string) => { // img要素に配置する。
      return new Promise((resolve) => {
        removeClass(document.querySelector('#image-cropper'), M_HIDE);
        const image: HTMLImageElement = document.querySelector('#image-cropper img');
        const onload = (event: Event) => {
          const image = event.target;
          image.removeEventListener('load', onload, false);
          resolve(image);
        }
        image.addEventListener('load', onload, false);
        image.src = url;
      });
    }).then((image: HTMLImageElement) => { // cropperを表示する。
      if (cropper !== null) {
        cropper.destroy();
      }
      cropper = new Cropper(image, {
        aspectRatio: width / height,
        autoCropArea: 1.0,
        rotatable: false,
        scalable: false,
        zoomable: false,
      });
      addClass(document.querySelector('.m-frmFile label'), IS_CHANGED);
      (document.querySelector('.m-frmFile .m-btn') as HTMLInputElement).disabled = false;
    }).catch(() => {
      showModal();
      resetFileField();
    });
  }, false);
};
